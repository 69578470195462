import { Button } from '@mybridge/ui';
import { Divider } from '@mybridge/ui/divider';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { SlideFade } from '@mybridge/ui/transitions';
import { triggerFetchPosts } from 'packages/mybridgev4/store/actions/post.actions';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PostFormBody } from 'v4/components/post-form/body';
import {
  PostFormContext,
  usePostFormContext,
} from 'v4/components/post-form/context';
import { PostFormFooter } from 'v4/components/post-form/footer';
import { PostFormHeader } from 'v4/components/post-form/header';
import { PostBoxBody } from 'v4/components/post/body';
import { PostBoxContext, usePostBoxContext } from 'v4/components/post/context';
import { PostBoxHeader } from 'v4/components/post/header';

export const PostBoxQuotePostModal = ({ ...props }) => {
  const dispatch = useDispatch();

  const { post, refetch, quotePostModal,multiPost } = useContext(PostBoxContext);
  const { content, repost } = post;

  // useEffect(() => {
  //   console.log("Test eerer", multiPost)
  // },[multiPost])

  const refetchAll = () => {
    dispatch(triggerFetchPosts(true));
    quotePostModal?.onClose?.();
  };

  const postFormContext = usePostFormContext({
    post,
    refetchAll,
  });

  useEffect(() => {
    setTimeout(() => {
      postFormContext?.setText?.('');
    }, 10);

  }, []);

  const { formContainerRef, expanded } = postFormContext ?? {};
  return (
    <PostFormContext.Provider value={{ ...(postFormContext ?? {}) }}>
      <Modal size="2xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#3D5A80"
              fontSize="20px"
              textAlign="center"
              fontWeight="400">
            <Text>Quote Post</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Stack ref={formContainerRef} transition="all 0.2s">
              <Stack w="100%">
                <PostFormHeader multiPost={multiPost} />
                <PostFormBody showMedia={true} />
                <Stack
                  spacing={0}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius={5}
                  pb={2}
                >
                  <PostBoxHeader actionMenuEnabled={false} />
                  <PostBoxBody />
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                variant="secondary"
                onClick={(e) => quotePostModal?.onClose?.()}
              >
                Cancel
              </Button>
              <Button
                isLoading={postFormContext?.quotePostLoading}
                onClick={(e) => postFormContext?.quotePost?.()}
                variant="primary"
                isDisabled={
                  postFormContext?.text?.length <= 0 ||
                  postFormContext?.text?.length > postFormContext?.textMaxLength
                }
              >
                Repost
              </Button>
            </HStack>
          </ModalFooter>
          {/* <Stack style={{padding:"12px 16px 12px 16px"}}>
            <PostFormFooter />
          </Stack> */}
        </ModalContent>
      </Modal>
    </PostFormContext.Provider>
  );
};
